import React, { Component } from 'react';
import Table from '../../components/Table';
import { Link } from 'react-router-dom';
import Nav from '../../components/Nav';
import moment from 'moment';
// import Filter from '../../components/filter';
import Filter from '../../components/filterall';
import Access from '../../components/accessManager';
import Modal from "../../components/modal";
import {
  Edit2,
  CheckCircle,
  XCircle,
  RefreshCw
} from "react-feather";
import { is } from 'date-fns/locale';

class Customers extends Component {
  state = {
    tableData: { data: [] },
    response: { data: [] },
    tableError: false,
    query: {},
    filter: {},
    table_loading: false,  
    editModal: false,
    activateModal: false,
   
  };
  timeout = null;
  render() {
    return (
      <div className=''>
        <Nav
          name='Reseller/Corporates'
          buttons={[
            {
              text: 'Add a Client',
              link: '/customer-create',
              permission: 'AGENT_READ',
            },
          ]}></Nav>
        <div className='mt-3 table-card border-0 card shado mx-3 shadow'>
          <div className='p-4'>
          <Filter
                            filter={[                            

                                {
                                    label: 'Status',
                                    name: 'is_active',
                                    options: [
                                        {
                                            label: 'Select Field',
                                            value: '',
                                        },
                                        {
                                            label: 'Active',
                                            value: '1',
                                        },
                                        {
                                            label: 'Inactive',
                                            value: '0',
                                        },                                      


                                    ],

                                },                      


                            ]}
                            onChange={(filter) => {
                                let { query } = this.state;                              

                                this.setState({ query: { ...query, ...filter } });
                                setTimeout(() => {
                                    this.fetchCustomers();
                                }, 0);
                            }}
                        />
         

            <Table
              search={['CompanyName','UserName','FirstName','LastName']}
              // sort="created_at"
              // sortDirection={-1}
              data={this.state.tableData}
              fetch={(params) => {
                this.setState({ query: params });
                setTimeout(() => {
                  this.fetchCustomers();
                }, 0);
              }}
              limit={25}
              loading={this.state.table_loading}
              fetchError={this.state.tableError}
              dateRange={['startdate', 'enddate']}
              dateRangeLabel='Date Range'
              csv={() => ({
                name: 'resellers',
                query: { ...this.state.query, ...this.state.filter },
              })}
            />
              <Modal
              visible={this.state.editModal}
              close={() => this.setState({ editModal: false })}>
              <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

              <form onSubmit={this.editRequest}>

                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">Callback</span>
                  <input className="form-control bg-light text-input" name="callback_settings" id="callback_settings" type="text" value={this.state.callback_settings} onChange={this.handleChange} style={{ width: '400px' }} />
                </div>
              
               


                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Edit</button>
                </div>
              </form>
            </Modal>


            <Modal
              visible={this.state.activateModal}
              close={() => this.setState({ activateModal: false, errors: {} })}>
              <h5 className="font-weight-bold" style={{ margin: "10px" }}>{this.state.title}</h5>

              <form onSubmit={this.resendRequest}>
              
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">CompanyName</span>
                  <input name="CompanyName" id="CompanyName" type="text" value={this.state.CompanyName} readOnly="readOnly" style={{ width: '400px' }} />

                </div>
                <div className="mx-3 d-inline-block mb-3">
                  <span className="ml-1 mb-2 d-block text-capitalize">EmailAddress</span>
                  <input type="text" name="EmailAddress" id="EmailAddress" value={this.state.EmailAddress} readOnly="readOnly" style={{ width: '400px' }} />
                </div>               
              
                  <div className="mx-3 d-inline-block mb-3">
                    <span className="ml-1 mb-2 d-block text-capitalize">Providerr:</span>
                    <select className="form-control bg-light text-input" name="is_active" id="is_active" type="text" value={this.state.is_active} onChange={this.handleChange} style={{ width: '400px' }}>
                    <option value="0" selected>Deactivate</option>
                      <option value="1">Activate</option>
                      
                    </select>
                  </div>
                
                <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                  <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Deactivate/Activate</button>
                </div>
              </form>
            </Modal>
          </div>
        </div>
      </div>
    );
  }

  fetchCustomers = () => {
    this.setState({ table_loading: true });

    //let { query } = this.state;
    let { query, filter } = this.state;
        let q = { ...query, ...filter };

    window.app
      .service('clients')
      .find({
        query: {
          ...q
          //...this.state.query, 
          //is_active: 1        
          
        },
      })
      .then((response) => {
        console.log("response is is",response)
        response.data = response.map((d) => {
          //var callback = JSON.parse(d.callback_settings);
          var callback=d.callback_settings ? JSON.parse(d.callback_settings) : "null" ;
          //console.log('obj is',callback);
          return {
            CompanyName: d.CompanyName,
            UserName: d.UserName,          
            EmailAddress: d.EmailAddress,
            PhoneNumber: d.PhoneNumber,                    
            created_at: moment(d.created_at).format('YYYY-MM-DD hh:mm:ss'),        
            updated_at: moment(d.updated_at).format('YYYY-MM-DD hh:mm:ss'),
            action: (
              <div className="d-flex flex-row">
              <Access permission='AGENT_READ'>
                <Link
                  className="btn btn-sm btn-primary px-3 btn-round" style={{marginRight: '1px'}}
                  to={`/service_client/${d.id}`}>
                  View Service Clients
                </Link>
                <Link
                  className="btn btn-sm btn-primary px-3 btn-round" style={{marginRight: '1px'}}
                  to={`/users/${d.id}`}>
                  View Portal Users
                </Link>
               
                {/* <button
                  onClick={() => {
                    this.setState({
                      editModal: true, activeDetails: d, callback_settings:callback.url, id: d.id
                    });

                  }}
                  className="btn btn-sm btn-primary px-3 btn-round" style={{marginRight: '1px'}}
                >
                  Update Callback
                </button> */}
                <button
                  onClick={() => {
                    this.setState({
                      activateModal: true, activeDetails: d, CompanyName:d.CompanyName, id: d.id, EmailAddress:d.EmailAddress, is_active:d.is_active
                    });

                  }}
                  className="btn btn-sm btn-primary px-3 btn-round" style={{marginRight: '1px'}}
                >
                  Deactivate
                </button>
              </Access>
            </div>
             ),        
          
          };
        });

        this.setState({ tableData: response, table_loading: false });
      })
      .catch((err) => {
        this.setState({ table_loading: false });
        console.error(err);
      });
  };
  editRequest = (event) => {
    event.preventDefault();
    var calsettings=this.state.callback_settings;
    var newcallback= {"url":calsettings,"tps":"10","retries":"1,5"}
    let id = this.state.id;
  

    let data = {
      callback_settings: JSON.stringify(newcallback)
     
    };

    // alert(callback_settings);
    
    if (
      !window.confirm("Are you sure that you want to Edit client callback?")
    )
      return false;

    // console.log(data)

    window.app
      .service('clients')
      .patch(id, data)
      .then((response) => {
        this.setState(response);

        window.alert2.show({
          loader: false,
          icon: <CheckCircle size={60}></CheckCircle>,
          title: 'Callback updated Successfully',
          buttons: true,
        });
        this.setState({ editModal: false })
      })
      .catch((err) => {
        window.alert2.show({
          loader: false,
          icon: <XCircle size={60} className='text-danger'></XCircle>,
          title: 'Error',
          message: err.message,
          buttons: true,
        });
        console.error(err);
      });

  };

  resendRequest = (event) => {
    event.preventDefault();

      let id = this.state.id;
      let data = {
        id: this.state.id,
        is_active: this.state.is_active        
      };
      if (
        !window.confirm("Are you sure that you want to deactivate client?")
      )
        return false;

      // console.log(data)

      window.app
        .service('clients')
        .patch(id, data)
        .then((response) => {
          this.setState(response);

          window.alert2.show({
            loader: false,
            icon: <CheckCircle size={60}></CheckCircle>,
            title: 'Cient deactivated Successfully',
            buttons: true,
          });          
        })
        .catch((err) => {
          window.alert2.show({
            loader: false,
            icon: <XCircle size={60} className='text-danger'></XCircle>,
            title: 'Error',
            message: err.message,
            buttons: true,
          });
          console.error(err);
        });
  
  };
  handleChange = (e) => {
    const { id, value } = e.target
    console.log(e.target.id);
    this.setState(prevState => ({
      ...prevState,
      [id]: value
    }))
  }
}

export default Customers;
