import React from 'react';
import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import { Info, XCircle } from 'react-feather';
import socketio from '@feathersjs/socketio-client';
const rest = require('@feathersjs/rest-client');
const request = require('request');

window.grafanaServer = 'https://portal.credofaster.co.ke/grafana/';
 //let endpoint = 'https://portal.credofaster.co.ke/portal_api'
 let endpoint = window.location.protocol+'//'+window.location.hostname+'/portal_api'


let use_socketio=false;


window.app = feathers();

if (use_socketio){
  const socket = io(endpoint);
  window.app.configure(socketio(socket,{
    timeout: 10000,
  }));
}else{
  const restClient = rest(endpoint,{
      headers: {}})
      window.app.configure(restClient.request(request));
}


window.app.configure(
  feathers.authentication({
    storage: window.localStorage,
  })
);

window.toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

if (localStorage.user) window.user = JSON.parse(localStorage.user);

window.logout = (force) => {
  if (force) {
    window.app.logout();
    window.location = '/login';
    localStorage.clear();
    return false;
  }

  window.alert2.confirm({
    icon: <Info size={70} className='my-3'></Info>,
    title: 'Are you sure that you want to Logout ?',
    message: 'If you agree your session will be terminated',
    confirmText: 'Log Out',
    onSubmit: () => {
      window.app.logout();
      window.location = '/login';
      localStorage.clear();
    },
  });
};

window.get = (model, callback, filter) => {
  window.app
    .service(model)
    .find(filter ? filter : {})
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      console.error(err);
    });
};

window.formatNumber = (x) => {
  if (isNaN(x)) return x;

  if (x < 9999) {
    return x;
  }

  if (x < 1000000) {
    return Math.ceil(x / 1000) + 'K';
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(2) + 'M';
  }

  if (x < 1000000000) {
    return Math.ceil(x / 1000000) + 'M';
  }

  if (x < 1000000000000) {
    return Math.ceil(x / 1000000000) + 'B';
  }

  return '1T+';
};

window.generateID = (length) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

window.hostname = (() => {
  const hostname = window.location.hostname;
  const parts = hostname.split('.'); 
  return parts.length >= 2 ? parts[0] : hostname;
})();

